import { useEffect, useState } from "react";

const Certificate = () => {
  const [imageLogo, setImageLogo] = useState();
  const [imageLogoData, setImageLogoData] = useState();
  const [imageQr, setImageQr] = useState();
  const [imageQrData, setImageQrData] = useState();
  const [textTitle, setTextTitle] = useState(
    "3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - "
  );
  const [textDescription, setTextDescription] = useState(
    "3 lignes of texte - 3 lignes of texte - 3 lignes of texte -3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - 3 lignes of texte - "
  );
  const [textInquiryNumber, setTextInquiryNumber] = useState(
    "Rue de Malagny 28 - 022 354 20 20 - 079 865 34 21"
  );
  const [textInquiryEmail, setTextInquiryEmail] = useState(
    "info@cavesa.ch - www.cavesa.ch"
  );
  const [textValidity, setTextValidity] = useState("XX.XX.XX");

  const [previewLogo, setPreviewLogo] = useState(undefined);
  const [previewQr, setPreviewQr] = useState(undefined);

  const loadFile = (file) => {
    console.log("Load file", file)
    return new Promise((resolve,reject)=> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log("Loaded", reader.result)
      resolve(reader.result.toString())
    };
    reader.onerror = error => reject(error);
  })}

  const onFileChange = async (event) => {
    const name = event.target.name;
    const file = event.target.files[0];
    console.log('on file change', file)
    console.log(name);

    if (name === "imageLogo") {
      console.log("img logo");
      setImageLogo(file);
      var fileData = await loadFile(file)
      console.log("fileData", fileData)
      setImageLogoData(fileData)
    }
    if (name === "imageQr") {
      console.log("img QR");
      setImageQr(file);
      var fileData = await loadFile(file)
      setImageQrData(fileData)
    }
  };

  const handleTextChange = (event) => {
    // const l = textTitle.length || 0;
    if (event.target.name === "textDescription")
      setTextDescription(event.target.value);
    if (event.target.name === "textTitle") {
      setTextTitle(event.target.value);
    }
    if (event.target.name === "textValidity")
      setTextValidity(event.target.value);
    if (event.target.name === "textInquiryEmail")
      setTextInquiryEmail(event.target.value);
    if (event.target.name === "textInquiryNumber")
      setTextInquiryNumber(event.target.value);
  };

  useEffect(() => {
    if (!imageLogo) {
      setPreviewLogo("https://via.placeholder.com/500x150.png");
      return;
    }

    const objectUrl = URL.createObjectURL(imageLogo);
    setPreviewLogo(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [imageLogo]);

  useEffect(() => {
    if (!imageQr) {
      setPreviewQr("https://via.placeholder.com/150.png");
      return;
    }

    const objectUrl = URL.createObjectURL(imageQr);
    setPreviewQr(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [imageQr]);

  const handleSave  = () => {
    console.log('handleSave')
    // const url = 'http://localhost:3061/dev/save'
    const url = 'https://pui2ujbl9a.execute-api.ap-southeast-1.amazonaws.com/prod/save'


    const data = {
      textTitle,
      textDescription,
      textInquiryNumber, 
      textInquiryEmail,
      textValidity,
      imageLogoData,
      imageQrData

    }
    fetch(url, {
      method:"POST",
      body: JSON.stringify(data)
    })
  }

  console.log("Render" , textTitle)
  return (
<div>
    <div className="flex flex-row p-5 center m-auto">
        <div className="p-12" style={{width:400}}> 

          <div style={{height:150}}>
            <div>
                Graphic 1 (500x150)
                </div>
                <div>
                Graphic 2 (150x150)
                </div>
          </div>
          <div className="mt-8" style={{height:160}}>
            <div className="font-2xl">
                Resumé de votre offre  - Pensez à inclure votre domaine de l’activité, une accroche, un point le plus valorisant si le logo ne l’indique pas. Max 71 characterr. 3 lignes BOLD 
            </div>
            <div>
            caractères: 
            <span style={textTitle.length > 71 ? {color:'red'}: {}}>
                  {textTitle != null ? textTitle.length : 0}/71</span>
            </div>
          </div>
          <div className="mt-8" style={{height:100}}>
            <div className="font-2xl">
              Description plus précise de votre offre , votre entreprise, conditions de l’offre etc  
              caractères: <span style={textDescription.length > 210 ? {color:'red'}: {}}>
                  {textDescription != null ? textDescription.length : 0}/210</span>
            </div>
          </div>

          <div className="mt-4" style={{height:30}}>
            <div className="font-2xl">
            Validite: XX.XX.XXXX  
              caractères: <span style={textValidity.length > 12 ? {color:'red'}: {}}>
                  {textValidity != null ? textValidity.length : 0}/12</span>
            </div>
          </div>

          <div className="mt-8" style={{height:50}}>
            <div className="font-2xl">
                Coordonnées -  indiquez comment voulez-vous être contacter par  vos clients  
                <span style={textInquiryNumber.length > 30 ? {color:'red'}: {}}>
                  {textInquiryNumber != null ? textInquiryNumber.length : 0}/210</span>
            </div>
          </div>
          <div className="mt-8" style={{height:50}}>
            <div className="font-2xl">
                Coordonnées -  indiquez comment voulez-vous être contacter par  vos clients  
                <span style={textInquiryEmail.length > 30 ? {color:'red'}: {}}>
                  {textInquiryEmail != null ? textInquiryEmail.length : 0}/210</span>
            </div>
          </div>
        </div>
    <div className="flex items-center" style={{ width:950, height:750 }}>
      <div className="w-full h-full p-12 border-4 border-blue-300">
        {/* Logo and QR */}
        <div className="flex flex-row justify-between">
          {/* Logo */}
          <div>
            <input
              type="file"
              accept="image/gif, image/jpeg, image/png"
              name="imageLogo"
              id="imageLogo"
              className="hidden"
              onChange={onFileChange}
            />
            <label htmlFor="imageLogo" className="cursor-pointer">
              <img src={previewLogo} style={{ maxWidth: 500, height: 150 }} />
            </label>
          </div>

          {/* QR */}
          <div>
            <input
              type="file"
              accept="image/gif, image/jpeg, image/png"
              name="imageQr"
              id="imageQr"
              className="hidden"
              onChange={onFileChange}
            />
            <label htmlFor="imageQr" className="cursor-pointer">
              <img src={previewQr} style={{ maxWidth: 150, height: 150 }} />
            </label>
          </div>
        </div>

        {/* Body --- Start */}
        <div className="mt-8">
          <textarea
            type="text"
            id="textTitle"
            name="textTitle"
            value={textTitle}
            rows={3}
            wrap="hard" 
            cols={31}
            style={{
              wrap:'hard',
              // maxHeight:'3em',
              backgroundColor:'#f0f0f0',
              overflow:'hidden'
            }}
            maxLength={93}
            className="w-full break-normal font-mono resize-none py-2 font-bold text-5xl"
            onChange={handleTextChange}
          />
        </div>
        <div className="mt-4">
          <textarea
            type="text"
            id="textDescription"
            name="textDescription"
            value={textDescription}
            rows={3}
            cols={70}
            maxLength={270}
            style={{
              wrap:'hard',
              // maxHeight:'3em',
              backgroundColor:'#f0f0f0',
              overflow:'hidden'
            }}
            className="w-full break-normal font-mono resize-none py-2 font-bold text-xl"
            onChange={handleTextChange}
          />
        </div>
        {/* Body --- End */}

        {/* Footer --- Start */}
        <div
          className="border-b-2 border-gray-400 flex "
          style={{ justifyContent: "flex-end" }}
        >
          <div className="bg-gray-400 px-4 py-0 flex flex-row justify-center">
            <div>Validitie: </div>

            <input
              type="text"
              id="textValidity"
              name="textValidity"
              style={{
                backgroundColor:'#f0f0f0',
              }}
              value={textValidity}
              className="w-full break-normal font-mono resize-none py-2 font-bold text-xl 
                bg-gray-400 focus:outline-none"
              onChange={handleTextChange}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="w-full mt-4 text-gray-600">
            <input
              type="text"
              id="textInquiryNumber"
              name="textInquiryNumber"
              value={textInquiryNumber}
              style={{
                backgroundColor:'#f0f0f0',
              }}
              className="w-full break-normal font-mono resize-none py-2 font-bold text-xl"
              onChange={handleTextChange}
            />
          </div>

          <div className="w-full mt-1 text-gray-600">
            <input
              type="text"
              id="textInquiryEmail"
              name="textInquiryEmail"
              value={textInquiryEmail}
              style={{
                backgroundColor:'#f0f0f0',
              }}
              className="w-full break-normal font-mono resize-none py-2 font-bold text-xl"
              onChange={handleTextChange}
            />
          </div>
        </div>
        
      </div>
    </div>
    </div>
    <div className="p-5">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default Certificate;
